import { all, fork } from 'redux-saga/effects';
import status from './status';
import user from './user';
import terminal from './terminal';
import amounts from './amounts';
import payment from './payment';
import telepayService from './telepayService';
import sbp from './sbp';
import tips from './tips';
import cardPayout from './card-payout';

export default function* root() {
  yield all([
    fork(status),
    fork(user),
    fork(terminal),
    fork(amounts),
    fork(payment),
    fork(telepayService),
    fork(sbp),
    fork(tips),
    fork(cardPayout),
  ]);
}
