import axios from 'axios';
// import i18n from '../../i18n';

import { URL_CREATE_BUSINESS_CARD } from '../../utils/variables';

const createBusinessCard = (body, headers) => axios
  .post(URL_CREATE_BUSINESS_CARD, body, { headers })
  .then(({ data }) => data || {})
  .catch((err) => Promise.reject(err));

export default createBusinessCard;
