import axios from 'axios';
// import i18n from '../../i18n';

import { URL_CREATE_CARD_PAYOUT_BANK } from '../../utils/variables';

const createCardPayoutBank = (body) => axios
  .post(URL_CREATE_CARD_PAYOUT_BANK, body)
  .then(({ data }) => data || {})
  .catch((err) => Promise.reject(err));

export default createCardPayoutBank;
