import axios from 'axios';
// import i18n from '../../i18n';

import { URL_CHECK_CARD_PAYOUT_STATUS } from '../../utils/variables';

const checkCardPayoutStatus = (body) => axios
  .post(URL_CHECK_CARD_PAYOUT_STATUS, body)
  .then(({ data }) => data || {})
  .catch((err) => Promise.reject(err));

export default checkCardPayoutStatus;
