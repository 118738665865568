import axios from 'axios';
// import i18n from '../../i18n';

import { URL_GET_BUSINESS_CARDS } from '../../utils/variables';

const getBusinessCards = (headers) => axios
  .get(URL_GET_BUSINESS_CARDS, { headers, params: { limit: 0 } })
  .then(({ data }) => data || {})
  .catch((err) => Promise.reject(err));

export default getBusinessCards;
