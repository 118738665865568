import axios from 'axios';
// import i18n from '../../i18n';

import { URL_GET_CARD_PAYOUT_EXTRA_DATA } from '../../utils/variables';

const getCardPayoutExtraData = (body, headers) => axios
  .post(URL_GET_CARD_PAYOUT_EXTRA_DATA, body, { headers })
  .then(({ data }) => data || {})
  .catch((err) => Promise.reject(err));

export default getCardPayoutExtraData;
