import { takeLatest, put, call } from 'redux-saga/effects';
import xml2js from 'xml2js';
import { createDetachedSignature, createHash } from 'crypto-pro';
import moment from 'moment';
import humps from 'humps';

import refreshToken from '../../services/api/refreshToken';
import getBusinessCards from '../../services/api/getBusinessCards';
import createBusinessCard from '../../services/api/createBusinessCard';
import createCardPayout from '../../services/api/createCardPayout';
import createCardPayoutBank from '../../services/api/createCardPayoutBank';
import checkCardPayoutStatus from '../../services/api/checkCardPayoutStatus';
import getCardPayoutExtraData from '../../services/api/getCardPayoutExtraData';

import { getCardPayoutOperationHeaders } from '../../utils/function';

function* getBusinessCardsSaga({ basisData }) {
  const headers = getCardPayoutOperationHeaders(basisData);

  try {
    const data = yield call(getBusinessCards, headers);

    yield put({
      type: 'GET_BUSINESS_CARDS_SUCCESS',
      payload: data,
    });
  } catch ({ response }) {
    if (response.status === 401) {
      try {
        const auth = yield call(refreshToken, { refresh: basisData.refresh });
        const data = yield call(getBusinessCards, {
          ...headers,
          Authorization: `Bearer ${auth.access}`,
        });

        yield put({
          type: 'GET_BUSINESS_CARDS_SUCCESS',
          payload: data,
        });
      } catch (err) {
        window.top.postMessage(JSON.stringify({ error: response }), '*');
      }
    } else {
      window.top.postMessage(JSON.stringify({ error: response }), '*');
    }

    yield put({
      type: 'GET_BUSINESS_CARDS_FAIL',
    });
  }
}

function* createBusinessCardSaga({ body, basisData, formikBag }) {
  const headers = getCardPayoutOperationHeaders(basisData);

  try {
    const data = yield call(createBusinessCard, body, headers);

    window.top.postMessage(
      JSON.stringify({
        result: {
          message: 'Бизнес-карта успешно добавлена',
        },
      }),
      '*',
    );

    yield put({
      type: 'CREATE_BUSINESS_CARD_SUCCESS',
      payload: data,
    });
  } catch ({ response }) {
    if (response.status === 401) {
      try {
        const auth = yield call(refreshToken, { refresh: basisData.refresh });
        const data = yield call(createBusinessCard, body, {
          ...headers,
          Authorization: `Bearer ${auth.access}`,
        });

        yield put({
          type: 'CREATE_BUSINESS_CARD_SUCCESS',
          payload: data,
        });
      } catch (err) {
        window.top.postMessage(JSON.stringify({ error: response }), '*');
      }
    } else if (response.status === 400) {
      formikBag.setErrors(humps.camelizeKeys(response.data));
    } else {
      window.top.postMessage(JSON.stringify({ error: response }), '*');
    }

    yield put({
      type: 'CREATE_BUSINESS_CARD_FAIL',
    });
  }
}

function* createCardPayoutSaga({
  values,
  basisData,
  certificateDetails,
  formikBag,
}) {
  const headers = getCardPayoutOperationHeaders(basisData);
  const body = {
    pan: values.pan,
    business_card_id: values.businessCardId,
    terminal: values.terminal,
    amount: values.amount,
    currency: values.currency,
    description: `${values.actOfAcceptNumber} ${moment(
      values.actOfAcceptDt,
    ).format('DD.MM.YYYY')}`,
    external_order_id: values.externalOrderId
      ? values.externalOrderId
      : values.safetyExternalOrderId,
  };

  let cardPayoutData;
  let cardPayoutExtraData;

  try {
    cardPayoutData = yield call(createCardPayout, body, headers);

    if (
      +cardPayoutData.status === 200
      && +cardPayoutData.data.payout.status === 6
    ) {
      throw new Error(cardPayoutData.data.payout.status_message);
    }
  } catch (e) {
    if (e.response) {
      if (e.response.status === 401) {
        try {
          const auth = yield call(refreshToken, { refresh: basisData.refresh });
          cardPayoutData = yield call(createCardPayout, body, {
            ...headers,
            Authorization: `Bearer ${auth.access}`,
          });
        } catch (err) {
          if (err.response.status === 400) {
            formikBag.setErrors(humps.camelizeKeys(err.response.data));

            yield put({
              type: 'CREATE_CARD_PAYOUT_FAIL',
            });
          } else {
            window.top.postMessage(
              JSON.stringify({ error: err.response }),
              '*',
            );

            yield put({
              type: 'CREATE_CARD_PAYOUT_FAIL',
            });
          }
        }
      } else if (e.response.status === 400) {
        formikBag.setErrors(humps.camelizeKeys(e.response.data));

        yield put({
          type: 'CREATE_CARD_PAYOUT_FAIL',
        });
      } else {
        window.top.postMessage(JSON.stringify({ error: e.response }), '*');

        yield put({
          type: 'CREATE_CARD_PAYOUT_FAIL',
        });
      }
    } else {
      window.top.postMessage(
        JSON.stringify({
          error: {
            status: +cardPayoutData.data.payout.status,
            message: e.message,
          },
        }),
        '*',
      );

      yield put({
        type: 'CREATE_CARD_PAYOUT_FAIL',
      });
    }
  }

  if (
    cardPayoutData
    && cardPayoutData.status
    && +cardPayoutData.status === 200
    && cardPayoutData.data
    && +cardPayoutData.data.payout.status !== 6
  ) {
    try {
      cardPayoutExtraData = yield call(
        getCardPayoutExtraData,
        {
          payout: cardPayoutData.data.payout.id,
        },
        headers,
      );
    } catch ({ response: res }) {
      if (res.status === 401) {
        try {
          const auth = yield call(refreshToken, { refresh: basisData.refresh });
          cardPayoutExtraData = yield call(
            getCardPayoutExtraData,
            {
              payout: cardPayoutData.data.payout.id,
            },
            {
              ...headers,
              Authorization: `Bearer ${auth.access}`,
            },
          );
        } catch (err) {
          window.top.postMessage(JSON.stringify({ error: err.response }), '*');

          yield put({
            type: 'CREATE_CARD_PAYOUT_FAIL',
          });
        }
      } else {
        window.top.postMessage(JSON.stringify({ error: res }), '*');

        yield put({
          type: 'CREATE_CARD_PAYOUT_FAIL',
        });
      }
    }
  }

  if (cardPayoutExtraData) {
    try {
      const notSignedCardPayoutRequestBody = {
        amt: {
          amount: {
            ccy: cardPayoutData.data.payout.currency,
            value: (+cardPayoutData.data.payout.amount).toFixed(2),
          },
        },
        crdtr: {
          card: {
            crdtrCardNum: +values.pan,
          },
          nm: values.holder,
        },
        dbtr: {
          card: {
            dbtrCardDt: `${cardPayoutData.data.business_card.exp_year}${cardPayoutData.data.business_card.exp_month}`,
            dbtrCardNum: +cardPayoutData.data.business_card.pan,
          },
          nm: cardPayoutExtraData.merchant_name,
          orgId: cardPayoutExtraData.client_code,
          pointId: cardPayoutExtraData.point_code,
        },
        pmtGrpHdr: {
          actOfAcceptDt: moment(values.actOfAcceptDt).format('YYYY-MM-DD'),
          actOfAcceptNumber: values.actOfAcceptNumber,
          creDtTm: moment().format('YYYY-MM-DDTHH:mm:ss'),
          msgId: cardPayoutData.data.payout.id,
        },
      };

      const builder = new xml2js.Builder({
        rootName: 'Payment',
        renderOpts: { pretty: false, allowEmpty: true },
      });

      const serialNumber = yield certificateDetails.getCadesProp(
        'SerialNumber',
      );
      const cert64 = yield certificateDetails.exportBase64();
      const certificateBase64 = cert64.replaceAll('\r\n', '');

      const basisObject = {
        cstmrPaymentForScrap: {
          ...notSignedCardPayoutRequestBody,
          signature: {
            certInfo: {
              certNum: serialNumber,
              x509Data: {
                x509Certificate: certificateBase64,
              },
            },
            signatureValue: '',
          },
        },
      };

      const xml = builder.buildObject(basisObject);

      const hash = yield createHash(xml, {
        hashedAlgorithm:
          window.cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
      });

      const signatureValue = yield createDetachedSignature(
        certificateDetails.thumbprint,
        hash,
        {
          hashedAlgorithm:
            window.cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
        },
      );

      basisObject.cstmrPaymentForScrap.signature.signatureValue = signatureValue.replaceAll('\r\n', '');

      const data = yield call(createCardPayoutBank, basisObject);

      window.top.postMessage(
        JSON.stringify({
          result: {
            message: 'Выплата успешно создана',
            data,
          },
        }),
        '*',
      );

      yield put({
        type: 'CREATE_CARD_PAYOUT_SUCCESS',
        payload: data,
      });
    } catch (err) {
      if (err.response) {
        window.top.postMessage(JSON.stringify({ error: err.response }), '*');
      } else {
        window.top.postMessage(
          JSON.stringify({
            error: {
              message: 'Что-то пошло не так. Попробуйте позже',
            },
          }),
          '*',
        );
      }

      yield put({
        type: 'CREATE_CARD_PAYOUT_FAIL',
      });
    }
  }
}

function* checkCardPayoutStatusSaga({ basisData, certificateDetails }) {
  try {
    const builder = new xml2js.Builder({
      rootName: 'StatusRequestRest',
      renderOpts: { pretty: false, allowEmpty: true },
    });

    const serialNumber = yield certificateDetails.getCadesProp('SerialNumber');
    const cert64 = yield certificateDetails.exportBase64();
    const certificateBase64 = cert64.replaceAll('\r\n', '');

    const stRqCstmrPmtStsRpt = {
      stRqGrpHdr: {
        creDtTm: moment().format('YYYY-MM-DDTHH:mm:ss'),
        msgId: basisData.cardPayoutStatusCheckInfo.id,
      },
      stRqOrgnlPmtInfAndSts: {
        orgnlMsgId: basisData.cardPayoutStatusCheckInfo.id,
        // orgnlMsgId: 435,
        orgnlMsgNmId: 'pmt4scrap',
        // orgnlMsgNmId: basisData.orgnlMsgNmId,
        // orgnlPkgId: basisData.cardPayoutStatusCheckInfo.bankFields.bankField1,
      },
      signature: {
        certInfo: {
          certNum: serialNumber,
          x509Data: {
            x509Certificate: certificateBase64,
          },
        },
        signatureValue: '',
      },
    };

    if (basisData.cardPayoutStatusCheckInfo.bankFields.bankField1) {
      stRqCstmrPmtStsRpt.stRqOrgnlPmtInfAndSts.orgnlPkgId = basisData.cardPayoutStatusCheckInfo.bankFields.bankField1;
    }

    const xml = builder.buildObject({ stRqCstmrPmtStsRpt });

    const hash = yield createHash(xml, {
      hashedAlgorithm:
        window.cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
    });

    const signatureValue = yield createDetachedSignature(
      certificateDetails.thumbprint,
      hash,
      {
        hashedAlgorithm:
          window.cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
      },
    );

    stRqCstmrPmtStsRpt.signature.signatureValue = signatureValue.replaceAll(
      '\r\n',
      '',
    );

    const data = yield call(checkCardPayoutStatus, { stRqCstmrPmtStsRpt });

    yield put({
      type: 'CHECK_CARD_PAYOUT_STATUS_SUCCESS',
      payload: data,
    });
  } catch (err) {
    if (err.response) {
      window.top.postMessage(JSON.stringify({ error: err.response }), '*');
    } else {
      window.top.postMessage(
        JSON.stringify({
          error: {
            message: 'Что-то пошло не так. Попробуйте позже',
          },
        }),
        '*',
      );
    }

    yield put({
      type: 'CHECK_CARD_PAYOUT_STATUS_FAIL',
    });
  }
}

function* cardPayout() {
  yield takeLatest('GET_BUSINESS_CARDS', getBusinessCardsSaga);
  yield takeLatest('CREATE_BUSINESS_CARD', createBusinessCardSaga);
  yield takeLatest('CREATE_CARD_PAYOUT', createCardPayoutSaga);
  yield takeLatest('CHECK_CARD_PAYOUT_STATUS', checkCardPayoutStatusSaga);
}

export default cardPayout;
