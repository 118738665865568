const initialState = {
  loading: false,
  businessCards: {},
  businessCardData: {},
  cardPayoutData: {},
  cardPayoutStatus: {},
};

const getBusinessCards = (state) => ({
  ...state,
  loading: true,
});

const getBusinessCardsSuccess = (state, payload) => ({
  ...state,
  businessCards: payload,
  loading: false,
});

const getBusinessCardsFail = (state) => ({
  ...state,
  loading: false,
});

const createBusinessCard = (state) => ({
  ...state,
  loading: true,
});

const createBusinessCardSuccess = (state, payload) => ({
  ...state,
  businessCardData: payload,
  loading: false,
});

const createBusinessCardFail = (state) => ({
  ...state,
  loading: false,
});

const createCardPayout = (state) => ({
  ...state,
  loading: true,
});

const createCardPayoutSuccess = (state, payload) => ({
  ...state,
  cardPayoutData: payload,
  loading: false,
});

const createCardPayoutFail = (state) => ({
  ...state,
  loading: false,
});

const checkCardPayoutStatus = (state) => ({
  ...state,
  loading: true,
});

const checkCardPayoutStatusSuccess = (state, payload) => ({
  ...state,
  cardPayoutStatus: payload,
  loading: false,
});

const checkCardPayoutStatusFail = (state) => ({
  ...state,
  loading: false,
});

function cardPayout(state = initialState, action) {
  switch (action.type) {
    case 'GET_BUSINESS_CARDS':
      return getBusinessCards(state);
    case 'GET_BUSINESS_CARDS_SUCCESS':
      return getBusinessCardsSuccess(state, action.payload);
    case 'GET_BUSINESS_CARDS_FAIL':
      return getBusinessCardsFail(state);
    case 'CREATE_BUSINESS_CARD':
      return createBusinessCard(state);
    case 'CREATE_BUSINESS_CARD_SUCCESS':
      return createBusinessCardSuccess(state, action.payload);
    case 'CREATE_BUSINESS_CARD_FAIL':
      return createBusinessCardFail(state);
    case 'CREATE_CARD_PAYOUT':
      return createCardPayout(state);
    case 'CREATE_CARD_PAYOUT_SUCCESS':
      return createCardPayoutSuccess(state, action.payload);
    case 'CREATE_CARD_PAYOUT_FAIL':
      return createCardPayoutFail(state, action.payload);
    case 'CHECK_CARD_PAYOUT_STATUS':
      return checkCardPayoutStatus(state);
    case 'CHECK_CARD_PAYOUT_STATUS_SUCCESS':
      return checkCardPayoutStatusSuccess(state, action.payload);
    case 'CHECK_CARD_PAYOUT_STATUS_FAIL':
      return checkCardPayoutStatusFail(state);
    default:
      return state;
  }
}

export default cardPayout;
