import axios from 'axios';
// import i18n from '../../i18n';

import { URL_CREATE_CARD_PAYOUT } from '../../utils/variables';

const createCardPayout = (body, headers) => axios
  .post(URL_CREATE_CARD_PAYOUT, body, { headers })
  .then(({ status, data }) => ({ status, data }) || {})
  .catch((err) => Promise.reject(err));

export default createCardPayout;
