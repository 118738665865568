import axios from 'axios';
// import i18n from '../../i18n';

import { URL_REFRESH_TOKEN } from '../../utils/variables';

const refreshToken = (body) => axios
  .post(URL_REFRESH_TOKEN, body)
  .then(({ data }) => data || {})
  .catch((err) => Promise.reject(err));

export default refreshToken;
