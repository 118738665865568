import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './assets/sass/style.sass';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/UI/ErrorBoundary';
import Preloader from './components/UI/Preloader';
import store from './store/store';
import history from './history';

const App = React.lazy(() => import('./App'));

// Sentry.init({
//   dsn: 'https://b0fdd07dba084a708ecbb7b7ec4c5792@sentry.invoice.su/9',
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],
//
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <Router history={history}>
          <Suspense
            fallback={(
              <Preloader />
              )}
          >
            <App />
          </Suspense>
        </Router>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
