import Parser from 'ua-parser-js';
import colors from 'simple-color-functions';
import i18n from '../i18n';

export function getSearchParameters() {
  function transformToAssocArray(prmstr) {
    const params = {};
    const prmarr = prmstr.split('&');
    for (let i = 0; i < prmarr.length; i++) {
      const tmparr = prmarr[i].split('=');
      params[tmparr[0]] = tmparr[1];
    }
    return params;
  }

  const prmstr = window.location.search.substr(1);
  return prmstr !== null && prmstr !== '' ? transformToAssocArray(prmstr) : {};
}

export const getColorThemeFromURL = () => {
  const params = getSearchParameters();
  return {
    colorText: params.colorText ? `#${params.colorText}` : '#fff',
    colorButtons: params.color ? `#${params.color}` : '#248ce4',
  };
};

export const getContrastTextColor = (background, text) => {
  // 3e5569
  if (colors(background).contrast(text) > 3) {
    return text;
  }
  if (colors(background).brightness(0.7).contrast('#fff') > 1.6) {
    return '#ffffff';
  }
  return '#3e5569';
};

const parser = Parser(window.navigator.userAgent);
export const userDevice = {
  dev_name: `${parser.device.vendor !== undefined ? parser.device.vendor : 'Desktop'} ${parser.device.model !== undefined ? parser.device.model : ''} ${parser.os.name} ${parser.os.version}`,
  browser: `${parser.browser.name} ${parser.browser.version}`,
  xscreen: window.screen.availWidth,
  yscreen: window.screen.availHeight,
};

export function checkAddress(value) {
  const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regEmail.test(String(value).toLowerCase())) {
    return { type: 'email', address: value };
  }
  const regTel = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/;
  if (regTel.test(String(value))) {
    let formatAddress = value.replace(/\D+/g, '');
    if (formatAddress.length === 11 && (formatAddress[0] === '8' || formatAddress[0] === '7')) formatAddress = formatAddress.substr(1);
    return { type: 'tel', address: `+7${formatAddress}` };
  }
  return false;
}

export const detectCard = (str, arr) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (str.indexOf(arr[i]) === 0) {
      return true;
    }
  }
  return false;
};

export function separateNumber(number) {
  if (number) {
    return number.replace(/.{4}/g, (i) => `${i} `);
  }
  return number;
}

export function validateLuhn(e) {
  const number = e.replace(/[\s_]/g, '');
  let sum = 0;

  if (number === '') {
    return true;
  }

  for (let i = 0; i < number.length; i += 1) {
    let cardNum = parseInt(number[i], 10);
    if ((number.length - i) % 2 === 0) {
      cardNum *= 2;
      if (cardNum > 9) {
        cardNum -= 9;
      }
    }
    sum += cardNum;
  }

  return sum % 10 === 0;
}

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line no-useless-escape
    `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const isIFrame = () => {
  let isFramed = false;
  try {
    // eslint-disable-next-line no-restricted-globals
    isFramed = window !== window.top || document !== top.document || self.location !== top.location;
  } catch (e) {
    isFramed = true;
  }

  return isFramed;
};

// export const getHost = () => (document.location.ancestorOrigins
// ? document.location.ancestorOrigins[0] : document.referrer) || window.location.host;
export const getHost = () => document.location.host;

export const replaceDots = (e) => {
  if (e.target.value.indexOf('.') !== -1) {
    e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 3);
  }
};

export const checkRequired = (textFields) => {
  let valid = true;
  textFields.forEach((i) => {
    if (i.required && (!i.value || /^\s*$/.test(i.value))) {
      valid = false;
    }
  });
  return valid;
};

export const translateField = (field) => {
  const language = i18n.language.slice(-2).toLowerCase();

  if (field) {
    return field[language] || field.en || field.ru || (typeof (field) === 'string' ? field : null);
  }
  return '';
};

export function iframeModalClose(show, setShow) {
  if (isIFrame() && window.confirm('Вы уверены, что хотите закрыть?')) {
    setShow(!show);
    if (window.parent) {
      window.parent.postMessage('InvoiceCloseModal', '*');
    }
  }
}

export const getCardPayoutOperationHeaders = (basisData) => ({
  Authorization: `Bearer ${basisData.token}`,
  'Company-Id': basisData.companyId,
});
